import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import BannerImage from './components/bannerImage';
import CourseItem from './components/courseItem';
import Footer from './components/footer';
import FacilitatorProfile from './components/facilitatorProfile';

import SandrinePhoto from './images/Sandrine.jpeg';
import StevePhoto from './images/Steve.jpg';

const Home = () => {
    return  (
        <div>
            <CssBaseline />
            <BannerImage />
            <Container comopnent="main" maxWidth="md">
                <Box sx={{textAlign:'center', mt:4}}>    
                    <Typography component="h1" variant="h2">Finding our way back home...</Typography>
                </Box>
                <Box sx={{mt:4}}>
                    <Typography variant="subtitle1" paragraph style={{fontSize:'14pt'}}>
                        <b>Back to Simplicity provides courses on mindfulness and the fundamental Buddhist teachings.</b><br/> 
                        We provide a bridge between the world of secular mindfulness and traditional Buddhist teachings and practices,
                        in a style that is intended to be open and welcoming to people from all backgrounds.
                        <br/><br/>
                    </Typography>
                </Box>
                <Box sx={{textAlign:'center', mt:4}}>    
                    <Typography component="h2" variant="h3">Discover Our Courses</Typography>
                </Box>
                <Box sx={{mt:4}}>
                    <Typography>
                        We provide courses during the autumn, winter and spring terms. The courses typilcally last 6 - 8 weeks.
                        Each course provides guided meditations to deepen your mindfulness practice combined with introductions
                        to fundamental Buddhist concepts, teachings and practices. 
                    </Typography>                  
                </Box>
                <Box sx={{mt:4,mb:6}}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                            <CourseItem 
                                title="Impermanence and the Open Heart: Compassion in the Midst of Change"
                                text="Change is perennial and all around, and yet we often hold it responsible for much of our suffering. Find out how to approach change with compassion and discover the Buddha's teaching on 'impermanence'."
                                image="https://images.unsplash.com/photo-1501718037961-bb722c970f08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80"
                                actionURL="/MailingListSignUp"
                                />
                        </Grid> 
                        {/* 
                        < Grid item xs={12} sm={6} md={4}>
                            <CourseItem 
                                title="The Origins of Mindulness: the Lives and Times of the Buddha"
                                text="Go deeper into mindfulness and find out about the foundational Buddhist teachings and the life of the historical Buddha"
                                image="https://images.unsplash.com/photo-1501718037961-bb722c970f08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80"
                                actionURL="/CourseSignUp"
                                />
                        </Grid>*/}
                        <Grid item xs={12} sm={6} md={4}>
                            <CourseItem 
                                title="The Four Noble Truths: finding meaning amidst the suffering"
                                text="Going beyond basic mindfulness and facing some home truths about our lives and the world. The very core of the Buddha's message about suffering and happiness."
                                image="https://images.unsplash.com/photo-1494157813258-be645b38918d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80"
                                actionURL="/MailingListSignUp"
                                />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <CourseItem 
                                title="The Eightfold Path: the 'how's and 'why's of living a good life"
                                text="Add new dimensions to your mindfulness practice and new perspectives to your life.  Learn about the Buddha's recipe for a good and meaningful life."
                                image="https://images.unsplash.com/photo-1474557157379-8aa74a6ef541?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80"
                                actionURL="/MailingListSignUp"
                                />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <CourseItem 
                                title="Mahayana Buddhism: the unbounded heart of compassion"
                                text="The Mahayan tradition invites us to develop unbounded love and compassion for all beings. Explore the background and practices of this tradition."
                                image="https://images.unsplash.com/photo-1504021624863-054aa77f753f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80"
                                actionURL="/MailingListSignUp"
                                />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <CourseItem 
                                title="Cool, Calm and Kind : the Buddha's moral message"
                                text="Morality is key in Buddhist thought and practice, but differs from the way we approach it in the West. Learn how Buddhist morality is based in wisdom and love - both for yourself and others."
                                image="https://images.unsplash.com/photo-1519872675436-7d08e1bdc855?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80"
                                actionURL="/MailingListSignUp"
                                />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <CourseItem 
                                title="The Paramitas: the 6 perfections of the bodhisattva"
                                text="The bodhisattvas are sometimes referred to as trainee Buddhas. Their training consists of the so-called 6 perfections. Learn about this path of selfless perfection."
                                image="https://images.unsplash.com/photo-1476725974034-6788d424c132?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80"
                                actionURL="/MailingListSignUp"
                                />
                        </Grid>                                                    
                    </Grid>                        
                </Box>
                <Box sx={{textAlign:'center', mt:4, mb:6}}>    
                    <Typography component="h2" variant="h3">Meet Our Facilitators</Typography>
                </Box>
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={6}>
                        <FacilitatorProfile 
                            imageURL={SandrinePhoto} 
                            name="Sandrine" 
                            bio="is a qualified secular mindfulness instructor with 7 years teaching experience and has been a practicing Buddhist for over 27 years."
                        />                        
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FacilitatorProfile 
                            imageURL={StevePhoto} 
                            name="Steve" 
                            bio="has been studying Buddhism and its philosophy, as well as practicing Buddhist meditations, for 30 years. He has been a regular faciltator on our courses."
                        />                        
                    </Grid>                        
                </Grid>
                <Box sx={{textAlign:'left', mt:4, mb:6}}>  
                    Both Sandrine and Steve are students of teachers from the <Link href="http://www.drukpa.org" underline="hover" target="_blank">Drukpa</Link> lineage of Tibetan Buddhism.
                    They have attended many of the yearly retreats in the UK and abroad and have participated in pilgrimages to many of the holy sites in India and the Himalayas.<br/>
                    They were encouraged by their teachers to run these mindfulness courses for the benefit of the general public. 
                </Box>
                <Footer />
            </Container>
        </div>
    )
  };
  
  export default Home;