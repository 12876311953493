
import * as React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';


export default function CourseFeedbackForm() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      course: data.get('course_name'),
    });
    let form = event.currentTarget;
    form.target='_self';
    form.action = '/FeedbackForm/submitFeedback.php';
    form.method = 'POST';
    form.submit();
  };

  return (
      <Container component="div" maxWidth="false">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

            { /*
            <Typography component="h2" variant="h5">
            Feedback - Share Your Thoughts With Us!
            </Typography>
            */ }

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <Typography>
                                Name of the course you are providing feedback for: 
                            </Typography>
                            <TextField
                                id="course_name"
                                name="course_name"
                                variant="outlined"
                                fullWidth
                                label="Course Name"
                                multiline
                                rows={4}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <Typography>
                                Did the course meet your expectations? 
                            </Typography>
                            <TextField
                                id="met_expectations"
                                name="met_expectations"
                                variant="outlined"
                                fullWidth
                                label="Your comments..."
                                multiline
                                rows={4}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <Typography>
                            What did you get out of the course that was helpful?
                            </Typography>
                            <TextField
                                id="helpful_aspects"
                                name="helpful_aspects"
                                variant="outlined"
                                fullWidth
                                label="Please describe"
                                multiline
                                rows={4}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ mt: 2, mb: 2 }}>
                        <Typography>
                        Was there anything you would have liked to have been different, or more/less of, in the contents of the course and in the homepractice? 
                        </Typography>
                        <TextField
                            id="requests"
                            name="requests"
                            variant="outlined"
                            fullWidth
                            label="Please describe"
                            multiline
                            rows={4}
                        />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ mt: 2, mb: 2 }}>
                        <Typography>
                        Any other comments?
                        </Typography>
                        <TextField
                            id="comments"
                            name="comments"
                            variant="outlined"
                            fullWidth
                            label="Your comments..."
                            multiline
                            rows={4}
                        />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ mt: 2, mb: 2 }}>
                        <Typography>
                        Testimonials are very welcome. If you would like provide a testimonial for the course that you would be happy for us to share on the website or in newsletters, then please write your review below.
                        You can choose whether or not you would like to add you name or initials to the testimonial.  
                        </Typography>
                        <TextField
                            id="testimonial"
                            name="testimonial"
                            variant="outlined"
                            fullWidth
                            label="Your testimonial"
                            multiline
                            rows={4}
                        />
                        </Box>
                    </Grid>                                
                </Grid>

                <Box sx={{ mt: 2, mb: 2 }}>
                    <Button
                    type="submit"
                    color="primary"
                    fullWidth
                    variant="contained"
                    >
                    Submit Feedback
                    </Button>
                </Box>

            </Box>
        </Box>
        <br />
      </Container>
  );
};

