import * as React from 'react';
import HomeIcon from '@material-ui/icons/Home';  
import {AppBar, Box, Link, Toolbar, Typography} from '@material-ui/core';

function Header(){
    return (
            <AppBar position='static'>
                <Toolbar>
                    <Box sx={{width:'100%'}}>
                        <Link href="/" color="inherit"><HomeIcon  /></Link>
                    </Box>
                    <Box sx={{ width:'100%', textAlign:'center'}}>
                        <Typography component="div" variant="h4">
                            Back to Simplicity
                        </Typography>
                    </Box>
                    <Box sx={{width:'100%', textAlign:'right'}}>
                        <Link href="/MailingListSignUp" color="inherit" underline="none" >Sign Up Form</Link>
                    </Box>
                </Toolbar>
            </AppBar>

    );
}
export default Header;