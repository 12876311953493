
import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockRounded';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CopyrightNotice from './copyrightNotice';


export default function MailingListForm() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      name: data.get('name'),
    });
    let form = event.currentTarget;
    form.target='_self';
    form.action = '/MailingListSignUp/mailingList.php';
    form.method = 'POST';
    form.submit();
  };

  return (
      <Container component="div" maxWidth="false">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h2" variant="h5">
          Mailing List Sign Up
          </Typography>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  autoComplete="Name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                    <TextField
                        id="course_topics"
                        name="course_topics"
                        variant="outlined"
                        fullWidth
                        label="Courses or topics of interest?"
                        multiline
                        rows={4}
                    />
                </Grid>               
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" name="allowEmails" />}
                  label="I want to receive updates on future courses by email."
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, mb: 2 }}>
            <Button
              type="submit"
              color="primary"
              fullWidth
              variant="contained"
            >
              Sign Up
            </Button>
            </Box>

          </Box>
        </Box>
        <br />
      </Container>
  );
};
