import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Footer from './components/footer';

const ApplicationSubmitted = () => {
    return (
          <Container component="main" maxWidth="sm">
              <CssBaseline />
              <Box
                  sx={{
                  marginTop: 24,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  }}
              >
                  <Typography component="h1" variant="h3">
                  Your application has been submitted...
                  </Typography>
                  <p>&nbsp;</p>
                  <Typography component="p" variant="h4">
                  Please now make your payment for the course using the following PayPal link:<br/><br/>
                  <Link href="https://www.paypal.com/paypalme/SteveToddUK/60" target="_blank" rel="noreferrer">paypal.me/SteveToddUK/60</Link>
                  </Typography>
                  <p>&nbsp;</p>
                  <p><b>N.B. If you are unable to afford the course fee then please let us know as we would prefer for everyone to be able to attend</b></p>
                  <br/>
                  <br/>
              </Box>
              <Footer />
          </Container>
  
    );  
  
  }; 
  
  export default ApplicationSubmitted;