
import * as React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';


export default function SignUpForm() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      name: data.get('name'),
    });
    let form = event.currentTarget;
    form.target='_self';
    form.action = '/CourseSignUp/signUpForm.php';
    form.method = 'POST';
    form.submit();
  };

  return (
      <Container component="div" maxWidth="false">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h2" variant="h5">
            Course Registration Form
          </Typography>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  autoComplete="Name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="phone"
                  label="Phone or Mobile Number"
                  type="tel"
                  id="phone"
                  autoComplete="mobile tel"
                />
                <Grid item xs={12}>
                    <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography>
                        Do you have any physical or mental health or any life events in the last year that we should be aware of?
                    </Typography>
                    <TextField
                        id="health"
                        name="health"
                        variant="outlined"
                        fullWidth
                        label="Please describe"
                        multiline
                        rows={4}
                    />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography>
                      Have you had any previous experience of mindfulness or Buddhist meditation? 
                    </Typography>
                    <TextField
                        id="experience"
                        name="experience"
                        variant="outlined"
                        fullWidth
                        label="Please describe"
                        multiline
                        rows={4}
                    />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography>
                        Where did you hear about this course?
                    </Typography>
                    <TextField
                        id="marketing"
                        name="marketing"
                        variant="outlined"
                        fullWidth
                        label="Please describe"
                        multiline
                        rows={4}
                    />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography>
                        What do you want to get out of doing the course?
                    </Typography>
                    <TextField
                        id="expectations"
                        name="expectations"
                        variant="outlined"
                        fullWidth
                        label="Please describe"
                        multiline
                        rows={4}
                    />
                    </Box>
                </Grid>                                
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" name="allowEmails"/>}
                  label="I want to receive updates on future courses by email."
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, mb: 2 }}>
            <Button
              type="submit"
              color="primary"
              fullWidth
              variant="contained"
            >
              Sign Up
            </Button>
            </Box>

          </Box>
        </Box>
        <br />
      </Container>
  );
};


/*
import {Button, TextField} from '@material-ui/core';


const SignUp = () => {
    var state = {
        name: "",
        message: "",
        email: "",
        subject: "",
        sent: false,
        buttonText: "Send Message",
        emailError: false,
      };
    return (
    <div>
        <h1>SignUp</h1>
        <br/><br/>
        <form className="contact-form" onSubmit={(e) => this.formSubmit(e)}>
        <TextField
          id="standard-multiline-flexible"
          label="Message"
          placeholder="Enter Message"
          variant="outlined"
          multiline
          rowsMax={4}
          value={state.message}
          onChange={(e) => this.setState({ message: e.target.value })}
          required
          type="text"
        />
        <br />
        <br />
        <br />

        <TextField
          id="outlined-basic"
          placeholder="Enter your name"
          label="Name"
          variant="outlined"
          value={state.name}
          onChange={(e) => this.setState({ name: e.target.value })}
          required
          type="text"
        />
        <br />
        <br />
        <br />

        <TextField
          id="outlined-basic"
          label="Email"
          placeholder="Enter email address"
          variant="outlined"
          value={state.email}
          onChange={(e) => this.handleChangeEmail(e)}
          error={state.emailError}
          required
          type="email"
        />
        <br />
        <br />
        <br />
        <TextField
          id="outlined-basic"
          placeholder="Enter Subject"
          label="Subject"
          variant="outlined"
          value={state.subject}
          onChange={(e) => this.setState({ subject: e.target.value })}
          required
        />
        <br />
        <br />
        <br />
        <div className="button--container">
          <Button type="submit" className="button button-primary">
            {state.buttonText}
          </Button>
        </div>
      </form>
    </div>
    );
  };

  export default SignUp;
  */