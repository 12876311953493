import {Box, Container, CssBaseline, Link, Typography}  from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import MailingListForm from './components/mailingListForm';
import Footer from './components/footer';


const MailingListSignUp = () => {
  return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                marginTop: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h3">
                Course Mailing List
                </Typography>
                <p>&nbsp;</p>
                <p><b>The course you are interested in is not currently scheduled. Please sign up to our mailing list below to 
                    register your interest in this course and to be notified when this course will next be running as well as other upcoming courses.</b></p>
              {/*      
                <p>Alternatively, if you are interested in our next scheduled course <b>"Impermanence and the Open Heart: Compassion in the Midst of Change"</b> which starts on Febuary 8th 2022 
            then please <Link href="/CourseSignUp" color="inherit" underline="hover" >click here to sign up for that course</Link>.</p> */}
                <br/>
                <br/>
                <MailingListForm />
            </Box>
            <Footer />
        </Container>

  );  

}; 

export default MailingListSignUp;