import * as React from 'react';
import Image from 'material-ui-image';
import Box from '@material-ui/core/Box';

export default function BannerImage() { 
    return (
        <Box sx={{
                height:'300px',
                position: 'relative',
                overflow: 'hidden'
            }}>
                <Image src="https://images.unsplash.com/32/RgJQ82pETlKd0B7QzcJO_5912578701_92397ba76c_b.jpg" 
                    style={{
                        position: 'absolute',
                        top:'-100%', left:'0', right:'0', bottom:'-100%',
                        margin: 'auto'
                    }}
                />
            
        </Box>
    );
};