import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CourseSignUpForm from './components/courseSignUpForm';
import Footer from './components/footer';


const CourseSignUp = () => {
  return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                marginTop: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h3">
                Sign up for a course
                </Typography>
                <p>&nbsp;</p>
                <p>Our next course will be <b>"Impermanence and the Open Heart: Compassion in the Midst of Change"</b> which starts on Febuary 8th 2022 
                    and runs for 4 weeks. The class is run over Zoom and each session lasts 90 minutes, running from 7pm - 8.30pm on Tuesday evenings. 
                    The course costs £60, payable after registration. <br/><br/>If you have trouble affording the fee then please let us know
                    as we would prefer for everyone who wants to, to be able to attend.</p>
                <br/>
                <br/>
                <CourseSignUpForm />
            </Box>
            <Footer />
        </Container>

  );  

}; 

export default CourseSignUp;