import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CourseFeedbackForm from './components/courseFeedbackForm';
import Footer from './components/footer';


const FeedbackForm = () => {
  return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                marginTop: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h3">
                Course Feedback
                </Typography>
                <p>&nbsp;</p>
                <p>We welcome your feedback on any of the Back to Simplicity courses you have attended. 
                   It is very useful if you can explain why you liked or disliked any aspect of the course, and anything you would like to see changed.
                   All the feedback is anonymous. You can optionally provide a testimonial and it is up to you whether you want to include your name or initials with that.                     
                </p>
                <br/>
                <CourseFeedbackForm />
            </Box>
            <Footer />
        </Container>

  );  

}; 

export default FeedbackForm;