import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Footer from './components/footer';

const ThankYouMsg = () => {
    return (
          <Container component="main" maxWidth="sm">
              <CssBaseline />
              <Box
                  sx={{
                  marginTop: 24,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  }}
              >
                  <Typography component="h1" variant="h3">
                  Thank You!
                  </Typography>
                  <p>&nbsp;</p>
                  <Typography component="h2" variant="h5">
                  <b>You are now signed up to the mailing list and will be sent information on upcoming courses.</b>
                  </Typography>
                  <p>&nbsp;</p>
                  <p>Our next course will be <b>"Origins of Mindfulness: the lives and times of the Buddha"</b> which starts on November 1st 2021 
                      and runs for 6 weeks. If you would like to sign up for this course then please <Link href="/CourseSignUp" color="inherit" underline="hover">click here</Link>.</p>
                  <br/>
                  <br/>
              </Box>
              <Footer />
          </Container>
  
    );  
  
  }; 
  
  export default ThankYouMsg;