
import React from 'react'

import { Card,
         CardActionArea,
         CardMedia,
         CardContent,
         Typography,
         CardActions,
         Button, 
         BottomNavigation}     from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    root: {
      //margin: theme.spacing(3),
      width: 300, height: 425
    },
    media: {
      height: 140,
    },
    title: {
      color: theme.palette.primary.main
    },
    cardActionArea: {
      height: 376.5,
    },
    cardContent: {
      height: 236.5,
    },
    actionBlock: {

    },
    actionButton: {

    }

  }))

  export default function CourseItem (props) {

    const classes = useStyles()
    
    return (
      <Card className={classes.root}>
        <CardActionArea className={classes.cardActionArea}>
          <CardMedia
            className={classes.media}
            image={props.image}
            title="An invitation to mindfulness..."
          />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
              {props.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
            {props.text}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.actionBlock}>
          <Button size="small" color="primary" href={props.actionURL} className={classes.actionButton}>
            Sign Up For Course
          </Button>
        </CardActions>
      </Card>
    )
  }
  
 