import Header from './NavBar.js';
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './theme'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import CourseSignUp from "./pages/CourseSignUp";
import MailingListSignUp from "./pages/MailingListSignUp";
import ApplicationSubmitted from "./pages/ApplicationSubmitted";
import ThankYouMsg from "./pages/ThankYou";
import FeedbackForm from './pages/FeedbackForm.js';
import FeedbackSubmitted from './pages/FeedbackSubmitted.js';


export default function App() {
  return (
    <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Header/>

          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/CourseSignUp">
              <CourseSignUp />
            </Route>
            <Route path="/MailingListSignUp">
              <MailingListSignUp />
            </Route>
            <Route path="/FeedbackForm">
              <FeedbackForm />
            </Route>
            <Route path="/FeedbackSubmitted">
              <FeedbackSubmitted />
            </Route>                        
            <Route path="/ApplicationSubmitted">
              <ApplicationSubmitted />
            </Route>
            <Route path="/ThankYou">
              <ThankYouMsg />
            </Route>                        
          </Switch>
        </ThemeProvider>
    </Router>
  );
}


/*
function App() {
  return (
    <div className="App">
      <h1>Hello World!</h1>
    </div>
  );
}

function MainScreen() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}
*/

//export default App;

