import React from 'react'

import { Avatar,
         Box,
         Card,
         CardActionArea,
         CardMedia,
         CardContent,
         Typography,
         CardActions,
         Button, 
         emphasize}     from '@material-ui/core'

export default function FacilitatorProfile(props) {

    return (

        <Box>
            <Avatar src={props.imageURL} style={{ width: '100px', height: '100px', float:'left', marginRight:'12px'}} />
            <Typography>
                <b>{props.name}</b> {props.bio}
            </Typography>
        </Box>

    )

}