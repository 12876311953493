import * as React from 'react';
import {Facebook, Instagram} from '@material-ui/icons';  
import {Link, Box, Divider, Typography, Grid} from '@material-ui/core';
import CopyrightNotice from './copyrightNotice';

export default function Footer() {

    return (

        <Box sx={{mt:6,mb:6}}>
            <Divider />
            <Box sx={{mt:6,mb:6}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <Link href="http://drukpa.org.uk/" target="_blank">Drukpa UK</Link><br/>
                    <Link href="https://drukpa.org/" target="_blank">Drukpa.org</Link>                    
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                <Link href="http://www.livetolove.org.uk/" target="_blank">Live to Love UK</Link><br/>
                <Link href="https://www.livetolove.org/" target="_blank">Live to Love International</Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                <Link href="https://www.facebook.com/Back2Simplicity" target="_blank"><Facebook /></Link> <Instagram />
                </Grid>
            </Grid>
            </Box>
            <CopyrightNotice sx={{ mt: 6 }} />
        </Box>

    );

}

