import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Footer from './components/footer';

const FeedbackSubmitted = () => {
    return (
          <Container component="main" maxWidth="sm">
              <CssBaseline />
              <Box
                  sx={{
                  marginTop: 24,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  }}
              >
                  <p>&nbsp;</p>                  
                  <p>&nbsp;</p>                  
                  <Typography component="h1" variant="h4">
                  Your feedback has been submitted.<br/><br/> Many thanks for taking the time to share your thoughts.
                  </Typography>
                  <p>&nbsp;</p>                  
                  <p>&nbsp;</p>
                  <p><Link href="/" color="inherit" underline="hover" >Click here to browse courses</Link></p>
                  <br/>
                  <br/>
              </Box>
              <Footer />
          </Container>
  
    );  
  
  }; 
  
  export default FeedbackSubmitted;